export const container = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      stiffness: 20,
    },
  },
};

export const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.2,
      stiffness: 20,
    },
  },
};
